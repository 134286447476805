<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="开始时间">
            <el-date-picker v-model="searchForm.startTime" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" type="datetime" placeholder="选择开始时间"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="结束时间">
            <el-date-picker v-model="searchForm.endTime" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" type="datetime" placeholder="选择结束时间"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="事件名称">
            <el-input v-model="searchForm.eventNamesRegex" placeholder="请输入 事件名称"></el-input>
          </el-form-item>
        </el-col>
      </template>
    </Searchbar>

    <basicTable
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog append-to-body v-model="imgVisible" :title="title" width="30%">
      <el-image v-if="title=='查看图片'" style="display: block; margin: 0 auto; width: 500px; height: 200px" :src="imageUrl" :preview-src-list="[imageUrl]"></el-image>

      <iframe v-else frameborder="0" style="width: 500px;height: 200px" :src="imageUrl" allowfullscreen="true"></iframe>
    </el-dialog>
  </div>
</template>
<script>
import { dataList } from "@/api/outside/event";
import Searchbar from '@/components/Searchbar';
import { changeTime } from '@/util/date';
import { getPictureUrl } from "@/api/eventmanage/eventlist";
export default {
  components:{
    Searchbar,
  },
  data(){
    return{
      form:{},
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "areasAddressName",
          label: "发生地点",
          render: (row) => <div>{row.areasAddressName}</div>,
        },
        {
          prop: "eventName",
          label: "事件名称",
          render: (row) => <div>{row.eventName}</div>,
        },
        {
          prop: "cameraName",
          label: "设备名称",
          render: (row) => <div>{row.cameraName}</div>,
        },
        {
          prop: "addressName",
          label: "设备播放",
          render: (row) => <div>{row.addressName}</div>,
        },
        {
          prop: "startTime",
          label: "发生时间",
          render: (row) => <div>{changeTime(row.startTime / 1000)}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => (
              <div>
                <el-button type="text" icon="View" onClick={() => this.showDig(row.alarmImageUrl, 1)}>告警图片</el-button>
                <el-button type="text" icon="View" onClick={() => this.showDig(row.alarmVideoUrl, 2)}>告警视频</el-button>
              </div>
          )
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
      title:"",
      imgVisible:false,
      imageUrl: ""
    }
  },
  mounted() {
  },
  watch:{
  },
  created() {
    this.onLoad();
  },
  methods:{
    showDig(url, type){
      type == 1 ? this.title = '查看图片' : this.title = '查看视频';
      getPictureUrl({ url: type == 1 ? url[0] : url }).then(res=>{
        this.imgVisible = true;
        this.imageUrl = res.data.data;
      })
    },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad();
    },

    onSearch(){
      this.page.current = 1;

      if(this.searchForm.startTime && this.searchForm.endTime){
        if(new Date(this.searchForm.startTime).getTime() > new Date(this.searchForm.endTime).getTime()) return this.$message.error("开始时间不能大于结束时间");
      }
      this.onLoad({
        eventNamesRegex: this.searchForm.eventNamesRegex,
        startTime: new Date(this.searchForm.startTime).getTime(),
        endTime: new Date(this.searchForm.endTime).getTime()
      });
    },

    resetForm() {
      this.searchForm = {};
      this.onLoad();
    },


    onLoad(date){
      dataList({ pagesize:this.page.limit, pagenumber:this.page.current, ...date }).then(res=>{
        this.tableData = res.data.data.result;
        this.page.total = res.data.data.total;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tab{
  width: 100%;
  display: flex;
  flex-direction: column;
  >article{
    font-size: 20px;
    //padding: 0 50px;
    border: 1px solid;
    border-bottom: none;
    display: flex;
    align-items: center;
    &:last-child{
      border-bottom: 1px solid;
    }
    section{
      padding: 10px;
      &:nth-child(1){
        width: 140px;
        text-align: center;
      }
      &:nth-child(2){
        border-left: 1px solid;
        width: calc(100% - 140px);
        text-align: left;
      }
    }
  }
}
</style>